<template>
  <div class="vip-page">
    <!-- <header class="vip-type-list"><span>&nbsp;&nbsp;&nbsp;会员详情</span><el-button style="margin-right:15px" @click="this.$router.go(-1)" type="success">返回</el-button></header> -->
    <main>
      <div class="vip-msg">
        <div class="msg-left">
          <img style="width:200px;height:200px" src="@/assets/icons/icon_07.png" alt="头像">
        </div>
        <div class="msg-right">
          <div class="msg">
            <div class="vip-msg-list text-title">
              <p>姓名：</p>
              <p>性别：</p>
              <p>生日：</p>
              <p>手机：</p>
              <p>卡面号：</p>
              <p>卡芯号：</p>
              <p>余额：</p>
              <p>积分：</p>
              <!-- <p>钱龙账号：</p>
              <p>申请状态：</p> -->
            </div>
            <div class="vip-msg-list text-msg">
              <p>{{shopMsg.memberName}}</p>
              <p>{{shopMsg.memberSex==0?'保密':shopMsg.memberSex==1?'男':'女'}}</p>
              <p>{{shopMsg.memberBirthday}}</p>
              <p>{{shopMsg.memberPhone}} </p>
              <p>{{shopMsg.memberCardNumber}}
                <!-- <span style="color:skyblue">({{this.$route.query.cardName}})</span> -->
              </p>
              <p>{{shopMsg.memberCoreNumber}}</p>
              <p>￥{{shopMsg.memberBalance}}</p>
              <p>{{shopMsg.memberIntegral}}</p>
              <!-- <p v-if="!finance.financeAccount" style="color:red">未绑定</p>
              <p v-else >{{finance.financeAccount}}</p>
              <p v-if="finance.todayStatus==0" style="color:red">未申请</p>
              <p v-else >已申请</p> -->
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div class="vip-log">
          <el-tabs type="border-card" @tab-click="tabClick" :lazy='true'>
            <el-tab-pane @tab-click="tabClick(1)" label="消费记录">
              <One v-if="state[0] && shopMsg.shopName" :shopMsgData='shopMsg'/>
            </el-tab-pane>
            <el-tab-pane @tab-click="tabClick(2)" label="充值记录">
              <Two v-if="state[1]"/>
            </el-tab-pane>
            <el-tab-pane @tab-click="tabClick(3)" label="存茶记录">
              <Three v-if="state[2]"/>
            </el-tab-pane>
            <!-- <el-tab-pane label="Task">Task</el-tab-pane> -->
          </el-tabs>
      </div>
    </main>
  </div>
</template>
<script>
import One from"@c/vipCom/oneCom"
import Two from"@c/vipCom/twoCom"
import Three from"@c/vipCom/threeCom"
import {postData} from'@a'
import { ref } from 'vue'
import {useRoute} from'vue-router'
export default {
  components:{
    One,Two,Three,
  },
  setup(){
    let route = useRoute()
    const shopMsg = ref({})
    let state = ref([true,false,false])
    const info = ()=>{
      postData('report/member/get.json',{shopId:route.query.shopId,memberId:route.query.memberId}).then(res=>{
        res.data.memberBirthday = res.data.memberBirthday.substr(0,10)
        shopMsg.value = res.data
      })
    }
    info()
    const tabClick = (row)=>{
      switch(row.props.label){
        case '消费记录':
          state.value = [true,false,false]
          break;
        case '充值记录':
          state.value = [false,true,false]
          break;
        case '存茶记录':
          state.value = [false,false,true]
          break;
      }
    }
    return{
      shopMsg,
      tabClick,
      state,
    }
  }
}
</script>

<style scoped>
header{
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
  font-style: normal;
  font-size: 20px;
  text-align: left;
}
.vip-type-list{
  background-color: #D9EFFF;
  font-size: 18px;
  height: 65px;
  line-height: 65px;
   display: flex;
  justify-content:space-between;
  align-items: center;
  font-family: 'Microsoft YaHei';
  color: #58666e;
}
main{
  margin:15px 15px 0 15px;

  /* background-color: rgba(242, 242, 242, 1); */
}
.vip-msg{
  display: flex;
  /* padding-top: 20px; */
}
.msg-left{
  padding-left: 20px;
  width: 230px;
  height: 250px;
}
.msg-right{
  flex:1;
  height: 250px;
}
.msg{
  display: flex;
  color: #58666e;
  font-family: 'Microsoft YaHei';
  /* font-size: 14px; */
}
.text-title{
  width: 70px;
}
.text-msg{
  flex: 1;
}
.vip-msg-list{
  display: flex;
  flex-direction: column;
  font-size: 16px;
  height: 23px;
  line-height: 30px;
}
</style>