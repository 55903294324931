<template>
  <div>
    <el-table 
      :cell-style="textStyle" 
      :data="tableData" 
      stripe style="width: 95%;margin:0 auto"
      @cell-click="clickFun"
      background
    >
      <el-table-column prop="beginTime" label="下单时间" />
      <el-table-column prop="endTime" label="结算时间" />
      <el-table-column prop="tableName" label="台桌" />
      <el-table-column prop="incomeName" label="类型" />
      <el-table-column prop="incomeTypeText" label="结算方式" />
      <el-table-column prop="orderMoney" label="结算金额" />
      <el-table-column prop="incomeMoney" label="实收金额" />
      <el-table-column prop="shopName" label="消费店铺" />
      <el-table-column prop="check" label="详情" />
    </el-table>
    <div class="paging" v-if="pagingNum>10">
      <div style="margin:auto;float: right;">
        <el-pagination 
          background 
          @current-change='pagingReq' 
          layout="prev, pager, next" 
          :current-page="pagNum"
          :total="pagingNum">
        </el-pagination>
      </div>
    </div>
    <div>
      <!-- 台桌消费弹出框1 -->
      <el-dialog v-model="tab1"  top="50px"  width="75%">
        <template #default>
          <div id="tabOne" >
            <div v-for="i in tableDatail" :key="i">
              <div style="font-size:16px;padding:20px">
                <p>台号：{{i.tableName}}    翻台次数：{{i.tableCounter}}</p>
                <p>开始时间：{{i.beginTime}}    结算时间：{{i.endTime}}  编号：{{i.orderSerial}}</p>
              </div>
              <el-table :data="i.rows" style="width: 100%">
                <el-table-column prop="itemName" label="商品名称" width="180" />
                <el-table-column prop="itemCount" label="数量" width="180" />
                <el-table-column prop="itemUnit" label="单位" />
                <el-table-column prop="itemMoney" label="单价" />
                <el-table-column prop="itemDiscount" label="优惠折扣" />
                <el-table-column prop="finalMoney" label="折后价" />
              </el-table>       
            </div>
            <div>
              <p>合计：<span v-if="tableDatal.length === 1">{{(tableDatal.orderMoney-0).toFixed(2)}}</span><span v-else>{{((tableDatal.finalMoney-0) + (tableDatal.totalDiscount-0) + (tableDatal.reduceMoney-0)).toFixed(2)}}</span>  &nbsp;&nbsp;优惠：{{(tableDatal.totalDiscount-0).toFixed(2)}}&nbsp;&nbsp;折扣：￥{{(tableDatal.reduceMoney-0).toFixed(2)}}</p>
              <p>应收：{{(tableDatal.finalMoney-0).toFixed(2)}}</p>
              <p style="font-size:18px;white-space: pre-wrap;">其中：{{tableDatal.incomeMoneyText}}</p>
              <p>结算人：{{tableDatal.payUserName}}</p>
              <p>备注：{{tableDatal.orderDesc}}</p>
            </div>
          </div>
          
        </template>
        <template #footer>
          <div class="dialog-footer">
            <p>
              会员：{{shopMsg.shopName}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;卡号：{{shopMsg.memberCardNumber}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;类别：{{this.$route.query.cardName}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;余额：￥{{shopMsg.memberIntegral}}
            </p>
            <el-button type="primary" @click="clickFunCase('tabOne')">打印</el-button
            >
          </div>
        </template>
      </el-dialog>
      <!-- 台桌消费弹出框2 -->
      <el-dialog v-model="tab2" title="会员充值" width="75%">
        <template #default>
          <div id="tabTwo">
            <div style="font-size:16px;padding:20px">
              <p>会员：{{tableData2[0].memberCardNumber}}&nbsp;时间：{{tableData2[0].detailTime}}     编号：{{tableData2[0].orderSerial}}</p>
            </div>
            <el-table :data="tableData2" style="width: 100%">
              <el-table-column prop="incomeType" label="名称"/>
              <el-table-column prop="integralRate" label="数量"/>
              <el-table-column prop="incomeMoney" label="充值金额" />
              <el-table-column prop="incomeRule" label="实际金额"/>
            </el-table>       
            <div style="margin-top:30px">
              <p>合计：{{tableData2[0].incomeMoney}}  
                <span v-if="tableData2[0].givingRule">&nbsp;&nbsp;充值规则:充{{tableData2[0].incomeMoney}}送{{tableData2[0].givingRule}}</span>
              </p>
              <p style="font-size:16px;white-space: pre-wrap;">其中：{{tableData2[0].incomeType}}：{{tableData2[0].incomeMoney}}</p>
              <p>结算人：{{tableData2[0].dutyName}}</p>
              <p>备注：{{tableData2[0].orderDesc}}</p>
            </div>
          </div>
        </template>
        <template #footer>
          <!-- <div class="dialog-footer">
            <el-button type="primary" @click="clickFunCase('tabTwo')">打印</el-button
            >
          </div> -->
          <div class="dialog-footer">
            <p>
              会员：{{shopMsg.shopName}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;卡号：{{shopMsg.memberCardNumber}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;类别：{{this.$route.query.cardName}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;余额：￥{{shopMsg.memberIntegral}}
            </p>
            <el-button type="primary" @click="clickFunCase('tabTwo')">打印</el-button
            >
          </div>
        </template>
      </el-dialog>
      <!-- 台桌消费弹出框3 -->
      <el-dialog v-model="tab3" title="前台支出" width="65%">
        <template #default>
          <div id="tabThree">
            <div style="font-size:19px;padding:20px">
              <p>时间：{{tableData3.dutyName}}     操作人：{{tableData3.dutyName}}</p>
            </div>
            <el-table :data="tableData3.rows" style="width: 100%">
              <el-table-column prop="itemName" label="支出项目"/>
              <el-table-column prop="itemMoney" label="支出金额"/>
              <el-table-column prop="incomeType" label="支出渠道" />
            </el-table>       
            <div>
              <p>总计：{{tableData3.rows[0].itemMoney}}</p>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="dialog-footer">
            <el-button type="primary" @click="clickFunCase('tabThree')">打印</el-button
            >
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {ref} from"vue"
import {useRoute} from'vue-router'
import {postData} from'@a'
import {cell} from '@u/cell.js'
export default {
  props:['shopMsgData'],
  setup(props){
    let shopMsg = ref(props.shopMsgData)
    let route = useRoute()
    let tableData = ref([])
    const textStyle = ({ row, column })=>{
      if(!row)console.log(1)
      let style={}
      style['font-size'] = '14px'
      if(column.property === 'check' && column.label === '详情'){
        style.cursor= 'pointer'
        style.color = '#619BD8'
      }
      return style
    }
    let pagingNum = ref(10) //分页数
    let pagNum = ref(1)       //当前页
    const pagingReq = (num)=>{  //分页请求
      pagNum.value = num
      info()
    }
    const info = async () => {
      // if(tableData.value !== '') return
      tableData.value = []
      pagingNum.value = 0
      let param = {
        memberId:route.query.memberId,
        shopId:route.query.shopId,
        pageNo:pagNum.value,
        pageSize:10,
      }
      try {
        let res = await(postData('report/member/purchaseHistory.json',param))
        res.data.records.forEach(e => {
          e.incomeTypeText = funMoney(e.incomeType)
          e.check = "查看"
        });
        tableData.value = res.data.records
        pagingNum.value = res.data.total
      } catch (err) {
        console.log(err)
      }
    }
    info()
    const orderInfo = async(param)=>{
      let res = await(postData('pay/orderInfo.json',param))
      tableDatail.value = res.data.orders
      tableDatal.value = {totalDiscount:0,reduceMoney:0,totalMoney:0,finalMoney:0,incomeMoneyText:' ',vipType:0}
      if(tableDatail.value.length>1){
        for(let i = 0;i<tableDatail.value.length;i++){
          tableDatal.value.itemUnit = cell(tableDatal.value.itemUnit)
          tableDatal.value.totalDiscount += Number(tableDatail.value[i].totalDiscount)
          tableDatal.value.reduceMoney += Number(tableDatail.value[i].reduceMoney)
          tableDatal.value.totalMoney += Number(tableDatail.value[i].totalMoney) //合计
          tableDatal.value.finalMoney += Number(tableDatail.value[i].finalMoney)  //应收
          tableDatal.value.payUserName = tableDatail.value[i].payUserName
          tableDatal.value.incomes = tableDatail.value[0].incomes
          tableDatal.value.tableName = tableDatail.value[0].tableName
          tableDatal.value.tableCounter = tableDatail.value[0].tableCounter
          tableDatal.value.orderSerial = tableDatail.value[0].orderSerial
          tableDatal.value.vipType = tableDatail.value[0].cardId?3:0
          tableDatal.value.orderId = param.orderId
        }
      }else{
        tableDatal.value = tableDatail.value[0]
        tableDatal.value.vipType =res.data.orders[0].cardId?3:0
        tableDatal.value.orderId = param.orderId
      }
      tableDatal.value.incomeMoneyText = ' '
      for(let i = 0;i<tableDatal.value.incomes.length;i++){
        if(i!=0)tableDatal.value.incomeMoneyText = tableDatal.value.incomeMoneyText + '  +  '
        if(tableDatal.value.incomes[i].incomeType==0){
          tableDatal.value.incomeMoneyText = tableDatal.value.incomeMoneyText + '现金支出'+tableDatal.value.incomes[i].incomeMoney
        }else{
          tableDatal.value.incomeMoneyText = tableDatal.value.incomeMoneyText + funMoney(tableDatal.value.incomes[i].incomeType)+ " : " + tableDatal.value.incomes[i].incomeMoney
        }
      }
    }
    const clickFun = async(row, column)=>{
      if(column.label==="详情"){
        if(row.incomeType == 0){
          let param = {expendOrderId:row.expandOrderId,shopId:route.query.shopId,year:new Date(row.detailTime).getFullYear()}
          let res = await(postData('shop/payout/projectExpendDetail.json',param))          
          res.data.rows[0].incomeType =  funMoney(res.data.rows[0].incomeType)
          tab3.value = true
          tableData3.value = res.data

        }else if(!row.orderId && row.incomeName !== "备用金订单"){
          let res = await(postData('order/recharge/detail.json',{detailId:row.detailId}))
          res.data.incomeType =  funMoney(res.data.incomeType)
          res.data.incomeRule = res.data.incomeMoney + res.data.givingRule
          tableData2.value[0] = res.data
          tab2.value = true
        }else if(row.incomeName!="备用金订单"){
          tab1.value = true
          let param = {
            orderId:row.orderId,
            shopId:route.query.shopId,
            year: new Date(row.detailTime).getFullYear(),
            vipType:row.orderType,
          }
          orderInfo(param)
        }
      }
    }
    const funMoney = (d)=>{
      let text = ''
      switch(d){
        case -1:
          text =  '组合结算';
          break;
        case 0:
          text =  '支出';
          break;
        case 1:
          text =  '会员现金充值';
          break;
        case 2:
          text =  '会员储值卡结算';
          break;
        case 3:
          text =  '会员现金结算';
          break;
        case 4:
          text =  '散客现金结算';
          break;
        case 5:
          text =  '赊账';
          break;
        case 6:
          text =  '现金还款';
          break;
        case 7:
          text =  '免单';
          break;
        case 8:
          text =  '网银消费';
          break;
        case 9:
          text =  '支付宝消费';
          break;
        case 10:
          text =  '微信消费';
          break;
        case 11:
          text =  '团购消费';
          break;
        case 12:
          text =  '网银充值';
          break;
        case 13:
          text =  '支付宝充值';
          break;
        case 14:
          text =  '微信充值';
          break;
        case 15:
          text =  '网银还款';
          break;
        case 16:
          text =  '支付宝还款';
          break;
        case 17:
          text =  '微信还款';
          break;
        case 18:
          text =  '还款免单';
          break;
        case 19:
          text =  '团购还款';
          break;
        case 20:
          text =  '团购充值';
          break;
        case 21:
          text =  '积分结算';
          break;
        case 22:
          text =  '代金卷结算'
          break;
        case 23:
          text =  '会员储值卡还款';
          break;
        case 24:
          text =  '网银支出';
          break;
        case 25:
          text =  '支付宝支出';
          break;
        case 26:
          text =  '微信支出';
          break;
        case 27:
          text =  '团购支出';
          break;
        case 28:
          text =  '扫码收入';
          break;
        case 29:
          text =  '扫码还款';
          break;
        case 30:
          text =  '扫码充值';
           break;   
        default:
          text =  "-";
          break;
      }
      return text
    }
    let tab1 = ref(false)
    let tab2 = ref(false)
    let tab3 = ref(false)
    let tableDatail = ref([])
    let tableDatal = ref([])
    let tableData2 = ref([])
    let tableData3 = ref([])
    const clickFunCase = (id)=>{
      window.printJS(id, 'html')
    }
    return{
      tableData,
      pagingNum,
      pagNum,
      pagingReq,
      textStyle,
      clickFun,
      tab1,
      tab2,
      tab3,
      clickFunCase,
      tableDatail,
      tableDatal,
      tableData2,
      tableData3,
      shopMsg,
    }

  }
}
</script>

<style scoped>
.dialog-footer{
  background-color: #D9EFFF;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>