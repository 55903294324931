<template>
  <div>
    <el-table 
      background
      :cell-style="textStyle" 
      :data="tableData" 
      stripe style="width: 95%;margin:0 auto"
      @cell-click="clickFun"
    >
      <el-table-column prop="updateTime" label="时间" />
      <el-table-column prop="goodsName" label="商品名称" />
      <el-table-column prop="stockCount" label="当前剩余" />
      <el-table-column prop="" label="存茶店铺" />
      <el-table-column prop="check" label="详情" />
    </el-table>
    <div class="paging" v-if="pagingNum>10">
      <div style="margin:auto;float: right;">
        <el-pagination 
          background 
          @current-change='pagingReq' 
          layout="prev, pager, next" 
          :current-page="pagNum"
          :total="pagingNum">
        </el-pagination>
      </div>
    </div>
    <div>
      <el-dialog v-model="vipTopUpState" top="50px" title="存茶明细" width="40%">
        <template #default>
          <div id="tabTwo">
            <div style="font-size:16px;padding:20px">
              <p>会员：{{vipTopUp[0].memberCardNumber}}&nbsp;时间：{{vipTopUp[0].detailTime}}     编号：{{vipTopUp[0].orderSerial}}</p>
            </div>
            <el-table :data="vipTopUp" style="width: 100%">
              <el-table-column prop="createTime" label="时间"/>
              <el-table-column prop="stockType" label="操作"/>
              <el-table-column prop="stockCount" label="数量" />
              <el-table-column prop="endCount" label="剩余数量"/>
              <el-table-column prop="userName" label="操作人"/>
            </el-table>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {ref} from"vue"
import {useRoute} from'vue-router'
import {postData} from'@a'
export default {
  setup(){
    let route = useRoute()
    let tableData = ref([])
    const textStyle = ({ row, column })=>{
            if(!row)console.log(1)
      let style={}
      style['font-size'] = '14px'
      if(column.property === 'check' && column.label === '详情'){
        style.cursor= 'pointer'
        style.color = '#619BD8'
      }
      return style
    }
    const viptopUpFun = async(stockId)=>{
      let res = await(postData('report/member/stockHistory.json',{stockId}))
      res.data.forEach(e => {
        e.createTime = e.createTime.slice(0,10)
        e.stockType = e.stockType===1?'扣除':'存茶'
      });
      vipTopUp.value = res.data
      vipTopUpState.value = true
    }
    const clickFun = (row, column)=>{
      if(column.label==="详情"){
        viptopUpFun(row.stockId)
      }
    }
    let pagingNum = ref(0) //分页数
    let pagNum = ref(1)       //当前页
    const pagingReq = (num)=>{  //分页请求
      pagNum.value = num
      info()
    }
    const info = async () => {
      let param = {
        memberId:route.query.memberId,
        shopId:route.query.shopId,
        pagNo:pagNum.value,
        pageSize:10,
      }
      try {
        let res = await(postData('report/member/stocks.json',param))
        res.data.records.forEach(e => {
          e.updateTime = e.updateTime.slice(0,10)
          e.check = "查看"
        });
        pagingNum.value = res.data.total
        tableData.value = res.data.records
      } catch (err) {
        console.log(err)
      }
    }
    info()
    let vipTopUpState = ref(false)
    let vipTopUp = ref([{data:'1234'}])
    return{
      tableData,
      pagingNum,
      pagNum,
      pagingReq,
      textStyle,
      clickFun,
      vipTopUpState,
      vipTopUp,
    }
  }
}
</script>