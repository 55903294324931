<template>
  <div>
    <el-table 
      :cell-style="textStyle" 
      :data="tableData" 
      stripe style="width: 95%;margin:0 auto"
      @cell-click="clickFun"
      background
    >
      <el-table-column prop="detailTime" label="时间" />
      <el-table-column label="充值前金额" >
        <template #default="props">
          <span>{{(Number(props.row.preBalance)).toFixed(2)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="realMoney" label="充值金额" />
      <el-table-column label="赠送金额">
        <template #default="props">
          <span>{{(Number(props.row.detailMoney) - Number(props.row.realMoney)).toFixed(2)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="finalBalance" label="当前金额" />
      <el-table-column prop="shopName" label="充值店铺" />
      <el-table-column prop="check" label="详情" />
    </el-table>
    <div class="paging" v-if="pagingNum>10">
      <div style="margin:auto;float: right;">
        <el-pagination 
          background 
          @current-change='pagingReq' 
          layout="prev, pager, next" 
          :current-page="pagNum"
          :total="pagingNum">
        </el-pagination>
      </div>
    </div>
    <div>
      <el-dialog v-model="tab2" top="50px" title="会员充值" width="75%">
        <template #default>
          <div id="tabTwo">
            <div style="font-size:16px;padding:20px">
              <p>会员：{{tableData2[0].memberCardNumber}}&nbsp;时间：{{tableData2[0].detailTime}}     编号：{{tableData2[0].orderSerial}}</p>
            </div>
            <el-table :data="tableData2" style="width: 100%">
              <el-table-column prop="incomeType" label="名称"/>
              <el-table-column prop="integralRate" label="数量"/>
              <el-table-column prop="incomeMoney" label="充值金额" />
              <el-table-column prop="finalBalance" label="实际金额">
                <template #default="props">
                  <span>{{((+(Number(props.row.finalBalance)).toFixed(2)) - (+(Number(props.row.preBalance)).toFixed(2))).toFixed(2)}}</span>
                </template>
              </el-table-column>
            </el-table>       
            <div style="margin-top:30px">
              <p>合计：￥{{tableData2[0].incomeMoney}}  
                <span v-if="tableData2[0].givingRule">&nbsp;&nbsp;充值规则:充￥{{tableData2[0].rechargeRules}}送￥{{tableData2[0].givingRule}}</span>
              </p>
              <p style="font-size:16px;white-space: pre-wrap;">其中：{{tableData2[0].incomeType}}：￥{{tableData2[0].incomeMoney}}</p>
              <p>结算人：{{tableData2[0].dutyName}}</p>
              <p>备注：{{tableData2[0].detailDesc}}</p>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="dialog-footer">
            <p v-if="tableData2[0]">
              会员：{{tableData2[0].memberName}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;卡号：{{tableData2[0].memberCardNumber}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;类别：{{this.$route.query.cardName}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;余额：￥{{tableData2[0].memberBalance}}
            </p>
            <el-button type="primary" @click="clickFunCase('tabTwo')">打印</el-button
            >
          </div>
        </template>
      </el-dialog>
    </div>
    <!-- <div>
      <el-dialog v-model="tab2" title="会员充值" width="75%">
        <template #default>
          <div id="tabTwo">
            <div style="font-size:16px;padding:20px">
              <p>会员：{{tableData2[0].memberCardNumber}}&nbsp;时间：{{tableData2[0].detailTime}}     编号：{{tableData2[0].orderSerial}}</p>
            </div>
            <el-table :data="tableData2" style="width: 100%">
              <el-table-column prop="name" label="名称" />
              <el-table-column prop="count" label="数量" />
              <el-table-column prop="realMoney" label="充值金额" />
              <el-table-column prop="detailMoney" label="实际金额"/>
            </el-table>       
            <div style="margin-top:30px">
              <p>合计：￥{{tableData2[0].realMoney}}  
                <span>&nbsp;&nbsp;充值规则:充￥{{tableData2[0].realMoney}}送￥{{(+tableData2[0].detailMoney) - (+tableData2[0].realMoney)}}</span>
              </p>
              <p style="font-size:16px;white-space: pre-wrap;">其中：{{tableData2[0].realMoney}}</p>
              <p>结算人：{{tableData2[0].modeType}}{{tableData2[0].dutyName}}</p>
              <p>备注：{{tableData2[0].detailDesc}}</p>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="dialog-footer">
            <p v-if="tableData2[0]">
              会员：{{shopMsg.shopName}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;卡号：{{shopMsg.memberCardNumber}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;类别：{{this.$route.query.cardName}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;余额：￥{{shopMsg.memberBalance}}
            </p>
            <el-button type="primary" @click="clickFunCase('tabTwo')">打印</el-button
            >
          </div>
        </template>
      </el-dialog>
    </div> -->
  </div>
</template>

<script>
import { ref} from"vue"
import { useRoute } from'vue-router'
import { postData } from'@a'
export default {
  setup(){
    let route = useRoute()
    let tableData = ref([])
    const textStyle = ({ row, column })=>{
      if(!row)console.log(1)
      let style={}
      style['font-size'] = '14px'
      if(column.property === 'check' && column.label === '详情'){
        style.cursor= 'pointer'
        style.color = '#619BD8'
      }
      return style
    }
    const clickFun = (row, column)=>{
      if(column.label==="详情"){
        tabInfo(row)
      }
    }
    let pagingNum = ref(0) //分页数
    let pagNum = ref(1)       //当前页
    const pagingReq = (num)=>{  //分页请求
      pagNum.value = num
      info()
    }
    const info = async () => {
      // if(tableData.value !== '') return
      let param = {
        memberId:route.query.memberId,
        shopId:route.query.shopId,
        pageNo:pagNum.value,
        pageSize:10,
      }
      try {
        let res = await(postData('report/member/rechargeHistory.json',param))
        console.log(res)
        res.data.records.forEach(e => {
          e.check = "查看"
        });
        pagingNum.value = res.data.total
        tableData.value = res.data.records
      } catch (err) {
        console.log(err)
      }
    }
    info()




    const funMoney = (d)=>{
      let text = ''
      switch(d){
        case -1:
          text =  '组合结算';
          break;
        case 0:
          text =  '支出';
          break;
        case 1:
          text =  '会员现金充值';
          break;
        case 2:
          text =  '会员储值卡结算';
          break;
        case 3:
          text =  '会员现金结算';
          break;
        case 4:
          text =  '散客现金结算';
          break;
        case 5:
          text =  '赊账';
          break;
        case 6:
          text =  '现金还款';
          break;
        case 7:
          text =  '免单';
          break;
        case 8:
          text =  '网银消费';
          break;
        case 9:
          text =  '支付宝消费';
          break;
        case 10:
          text =  '微信消费';
          break;
        case 11:
          text =  '团购消费';
          break;
        case 12:
          text =  '网银充值';
          break;
        case 13:
          text =  '支付宝充值';
          break;
        case 14:
          text =  '微信充值';
          break;
        case 15:
          text =  '网银还款';
          break;
        case 16:
          text =  '支付宝还款';
          break;
        case 17:
          text =  '微信还款';
          break;
        case 18:
          text =  '还款免单';
          break;
        case 19:
          text =  '团购还款';
          break;
        case 20:
          text =  '团购充值';
          break;
        case 21:
          text =  '积分结算';
          break;
        case 22:
          text =  '代金卷结算'
          break;
        case 23:
          text =  '会员储值卡还款';
          break;
        case 24:
          text =  '网银支出';
          break;
        case 25:
          text =  '支付宝支出';
          break;
        case 26:
          text =  '微信支出';
          break;
        case 27:
          text =  '团购支出';
          break;
        case 28:
          text =  '扫码收入';
          break;
        case 29:
          text =  '扫码还款';
          break;
        case 30:
          text =  '扫码充值';
           break;   
        default:
          text =  "-";
          break;
      }
      return text
    }

    const tabInfo = async(row)=>{
      // console.log(row)
      // row.modeType = '支付宝'
      // row.detail = funMoney(row.detailType)
      // row.name = '充值'
      // row.count = '1'
      // tableData2.value[0] = row
      // tab2.value = true




            let res = await(postData('order/recharge/detail.json',{detailId:row.detailId}))
            res.data.incomeType = funMoney(res.data.incomeType)
            tableData2.value[0] = res.data
            console.log(tableData2.value)
            tab2.value = true
      // res.data.incomeType =  funMoney(res.data.incomeType)
      // res.data.incomeRule = res.data.incomeMoney + res.data.givingRule
      // tableData2.value[0] = res.data
      // console.log(tableData2.value[0])
      // tab2.value = true
    }
    let tab2 = ref(false)
    let tableData2 = ref([])
    const clickFunCase = (id)=>{
      window.printJS(id, 'html')
    }
    return{
      tableData,
      pagingNum,
      pagNum,
      pagingReq,
      textStyle,
      clickFun,
      tab2,
      tableData2,
      clickFunCase,
    }

  }
}
</script>

<style scoped>
.dialog-footer{
  background-color: #D9EFFF;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>